import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Promotions from './pages/Promotions';
import Streams from './pages/Streams';
import Guide from './pages/Guide';
import GuideArticle from './pages/GuideArticle';
import Contact from './pages/Contact';
import { HelmetProvider } from 'react-helmet-async';
import { SidebarProvider } from './context/SidebarContext';

export default function App() {
  return (
    <HelmetProvider>
    <SidebarProvider>
      <div className="flex flex-col lg:flex-row min-h-screen bg-gray-900 text-white">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <Navbar />
          <main className="flex-1 overflow-y-auto pt-16 lg:pt-0">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<Navigate to="/" replace />} />
              <Route path="/promotions" element={<Promotions />} />
              <Route path="/streams" element={<Streams />} />
              <Route path="/guide" element={<Guide />} />
              <Route path="/guide/:articleId" element={<GuideArticle />} />
              <Route path="/contact" element={<Contact />} />

            </Routes>
          </main>
          <Footer />
        </div>
      </div>
    </SidebarProvider>
    </HelmetProvider>

  );
}