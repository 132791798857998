import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Home, Info, Gift, Play, BookOpen, Mail, Menu, X } from 'lucide-react';
import { useSidebar } from '../context/SidebarContext';

export default function Sidebar() {
  const { isOpen, setIsOpen } = useSidebar();
  const [collapsed, setCollapsed] = React.useState(false);

  const navItems = [
    { icon: Home, label: 'Home', path: '/' },
    { icon: Gift, label: 'Promotions', path: '/promotions' },
    { icon: Play, label: 'Streams', path: '/streams' },
    { icon: BookOpen, label: 'Guide', path: '/guide' },
    { icon: Mail, label: 'Contact', path: '/contact' },
  ];

  return (
    <>
      {/* Mobile overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-40 lg:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}

      {/* Sidebar */}
      <aside
        className={`fixed lg:static inset-y-0 left-0 z-50 bg-gray-800 transform transition-transform duration-300 ease-in-out
          ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
          ${collapsed ? 'w-16' : 'w-64'}`}
      >
        <div className="h-16 flex items-center justify-between px-4 border-b border-gray-700">
        {!collapsed && (
            <Link 
              to="/" 
              className="text-xl font-bold text-purple-500 hover:text-purple-400 transition-colors"
              onClick={() => setIsOpen(false)}
            >
              Trainwreckstv
            </Link>
          )}
          <button
            onClick={() => setCollapsed(!collapsed)}
            className="p-1 rounded-lg hover:bg-gray-700 lg:block hidden"
          >
            <Menu className="w-5 h-5" />
          </button>
        </div>

        <nav className="flex-1 pt-4">
          {navItems.map(({ icon: Icon, label, path }) => (
            <NavLink
              key={path}
              to={path}
              onClick={() => setIsOpen(false)}
              className={({ isActive }) =>
                `flex items-center px-4 py-3 ${
                  isActive ? 'bg-purple-600 text-white' : 'text-gray-400 hover:bg-gray-700 hover:text-white'
                }`
              }
            >
              <Icon className="w-5 h-5" />
              {!collapsed && <span className="ml-3">{label}</span>}
            </NavLink>
          ))}
        </nav>
      </aside>
    </>
  );
}